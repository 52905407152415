<template>
  <v-container fluid no-gutters>
    <B2cTabs/>

    <v-row justify="center" class="mt-10">
      <v-card style="width: 100%; min-width: 300px">
        <v-row>
          <v-col cols="10">
            <h2 class="pa-4">Default Pages</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="page in pageData.additional_pages"
                :key="page.id"
              >
                <v-expansion-panel-header
                  disable-icon-rotate
                  v-if="page.is_default == 1"
                >
                  <h3>{{ page.menu_label }}</h3>
                  <template v-slot:actions>
                    <v-btn
                      class="ma-1"
                      v-if="page.page_id"
                      color="blue-color "
                      fab
                      x-small
                      dark
                      :to="`/b2c/pages/additional?pid=${page.page_id}`"
                      title="Edit Page"
                    >
                      <v-icon color="white">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-expansion-panel-header>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card>
    </v-row>

    <v-row justify="center" class="mt-10">
      <v-card style="width: 100%; min-width: 300px">
        <v-row>
          <v-col cols="10">
            <h2 class="pa-4">Additional Pages</h2>
          </v-col>
          <v-col cols="2" class="text-right mt-2">
            <v-btn
              v-if="pageData.additional_pages.length > 0"
              small
              text
              outlined
              class="mx-4 white--text ma-2 blue-color"
              to="/b2c/pages/additional"
            >
              <v-icon color="#fff" small>mdi-plus</v-icon>
              Add New Page
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="page in pageData.additional_pages"
                :key="page.id"
              >
                <v-expansion-panel-header
                  disable-icon-rotate
                  v-if="page.is_default == 0"
                >
                  <h3>
                    {{ page.menu_label }} <sub>({{ page.path }})</sub>
                  </h3>
                  <template v-slot:actions>
                    <v-btn
                      class="ma-1"
                      v-if="page.page_id"
                      color="blue-color"
                      fab
                      x-small
                      dark
                      :to="`/b2c/pages/additional?pid=${page.page_id}`"
                      title="Edit Page"
                    >
                      <v-icon color="white">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="page.is_default == 0"
                      class="ma-1"
                      @click="deletePagePopup(page.page_id)"
                      fab
                      x-small
                      title="Remove Page"
                    >
                      <v-icon color="red">mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                </v-expansion-panel-header>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card>
    </v-row>

    <v-row justify="center" class="mt-10">
      <v-card style="width: 100%; min-width: 300px">
        <v-row>
          <v-col cols="10">
            <h2 class="pa-4">Website Menu</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header disable-icon-rotate>
                  <h3>Header Menu</h3>
                  <template v-slot:actions>
                    <v-btn
                      v-if="pageData.additional_pages.length > 0"
                      class="ma-1"
                      color="blue-color"
                      fab
                      x-small
                      dark
                      :to="`/b2c/menu?type=header`"
                      title="Edit Header Menu"
                    >
                      <v-icon color="white">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-expansion-panel-header>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header disable-icon-rotate>
                  <h3>Footer Menu</h3>
                  <template v-slot:actions>
                    <v-btn
                      v-if="pageData.additional_pages.length > 0"
                      class="ma-1"
                      color="blue-color"
                      fab
                      x-small
                      dark
                      :to="`/b2c/menu?type=footer`"
                      title="Edit Footer Menu"
                    >
                      <v-icon color="white">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-expansion-panel-header>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import B2cTabs from "@/views/B2CConfigurations/B2cTabs.vue";

export default {
  components: {B2cTabs},
  mounted() {
    this.btnShow = true;
    this.getData();
  },
  data() {
    return {
      partnerPopup: false,
      currentCarouselId: null,
      page: 1,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      btnShow: false,
      venueServiceIds: [],
      linkedPages: [],
      pageData: {
        additional_pages: [],
      },
      additional_page: {
        id: 0,
        path: "newPage",
        cover_title: "Title",
        sub_title: "Title for Description",
        description: "",
        cover_image: null,
        cover_path: null,
      },
      qube_configuration: null,
      is_arabic_enabled: false,
    };
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
  },
  methods: {
    getData() {
      this.pageData.additional_pages = [];
      this.showLoader("Loading");
      this.$http
        .get(`venues/b2c/configurations`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.qube_configuration = response.data.data.qube_configuration;
            this.is_arabic_enabled = response.data.data.enable_arabic;

            if (
              response.data.data.additional_pages &&
              response.data.data.additional_pages.length > 0
            ) {
              response.data.data.additional_pages.forEach((page, index) => {
                let tempObject = JSON.parse(
                  JSON.stringify(this.additional_page)
                );
                tempObject.id = index;
                tempObject.page_id = page.id;
                tempObject.path = page.path;
                tempObject.is_default = page.is_default;
                tempObject.menu_label = page.menu_label;
                tempObject.ar_menu_label = page.ar_menu_label;

                this.linkedPages.push(page.path);
                this.pageData.additional_pages.push(tempObject);
              });
            }
            this.hideLoader();
          }
        })
        .catch((error) => {
          console.log(error);
          this.hideLoader();
        });
    },
    deletePagePopup(id) {
      this.confirmModel = {
        id: id,
        title: `Do you want to delete this page?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete",
      };
    },
    confirmActions(data) {
      if (data.type == "delete") {
        this.showLoader("Delete Page");
        this.$http
          .post(`venues/b2c/additional/page/${data.id}`)
          .then(() => {
            this.hideLoader();
            this.confirmModel.id = null;
            this.getData();
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      }
    },
    removePage(index) {
      this.pageData.additional_pages.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}

.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
</style>
